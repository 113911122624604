<template>
  <div class="su-bg">
    <div class="banenr"></div>

    <div class="su-introduce">
      <div class="page1200">
        <div class="d-flex justify-content-between su-intro-msg">
          <div>
            <div class="t1">业务介绍</div>
            <div class="su-msg">
              深度整合高品质电商平台、各线品牌商及本地生活权益等商品资源，为企业客户提供全品类供应链输出服务。<br /> <br /> 通过线下商品线上化、全品类商品标准 SKU 化、以及场景化商品池标准输出化三个步骤，向各类企业电商平台输出标准化 API 接口，解决企业商城品类不全、利润不够、质量参差不齐、售后服务不及时等问题。
            </div>
          </div>
          <div>
            <img style="margin-top: 80px;" src="../assets/supply/su-instruce.png" />
          </div>
        </div>
        <div class="d-flex item-wrap">
          <div class="item">
            <div> <img src="../assets/supply/su-i-icon-5.png" /></div>
            <div class="item-tit">品类全</div>
            <p>全品类100w+sku</p>
            <p>涵盖实物/数字权益</p>
            <p>实物涵盖30+电商主营品类</p>
            <p>数字权益涵盖8大品类</p>
          </div>
          <div class="item">
            <div> <img src="../assets/supply/su-i-icon-1.png" /></div>
            <div class="item-tit">质量好</div>
            <p>京东、天猫等主流商品</p>
            <p>自营1000+知名品牌</p>
            <p>正品渠道货源</p>
            <p>假一罚十</p>
          </div>
          <div class="item">
            <div> <img src="../assets/supply/su-i-icon-2.png" /></div>
            <div class="item-tit">利润高</div>
            <p>协议价供货，利润空间大</p>
            <p>实时对比市场价，利润率可视化</p>
            <p>可自主定价</p>
          </div>
          <div class="item">
            <div> <img src="../assets/supply/su-i-icon-3.png" /></div>
            <div class="item-tit">发货快</div>
            <p>全国云仓发货，无需囤货</p>
            <p>操作灵活，时间地点不受限</p>
            <p>京东、顺丰、四通一达专业物流</p>
          </div>
          <div class="item">
            <div> <img src="../assets/supply/su-i-icon-4.png" /></div>
            <div class="item-tit">对接易</div>
            <p>API一键接入</p>
            <p>商品各类信息自动同步、实时更新</p>
            <p>对账快捷</p>
          </div>
        </div>
      </div>
    </div>

    <div class="su-ys">
      <div class="page1200">
        <div class="tit t1">
          我们的优势
        </div>
        <div class="tit-msg">100w+SKU商品 1000+品牌</div>
        <div class="d-flex one">
          <div><img src="../assets/supply/ys_1.png" /></div>
          <div><img src="../assets/supply/ys_2.png" /></div>
        </div>
        <div class="tit-msg">一键接入，实时比价</div>
        <div class="d-flex flex-wrap two">
          <div><img src="../assets/supply/ys_bj_1.png" /></div>
          <div><img src="../assets/supply/ys_bj_2.png" /></div>
          <div><img src="../assets/supply/ys_bj_3.png" /></div>
          <div><img src="../assets/supply/ys_bj_4.png" /></div>
          <div><img src="../assets/supply/ys_bj_5.png" /></div>
          <div><img src="../assets/supply/ys_bj_6.png" /></div>
        </div>
        <div class="tit-msg">标准接口，场景输出</div>
        <div class="d-flex three">
          <div><img src="../assets/supply/emw_1.png" /></div>
          <div><img src="../assets/supply/emw_2.png" /></div>
          <div><img src="../assets/supply/emw_3.png" /></div>
        </div>
        <div class="tit-msg">标配场景化商城</div>
        <div class="d-flex flex-wrap four">
          <div><img src="../assets/supply/s1.png" /></div>
          <div><img src="../assets/supply/s2.png" /></div>
          <div><img src="../assets/supply/s3.png" /></div>
          <div><img src="../assets/supply/s4.png" /></div>
          <div><img src="../assets/supply/s5.png" /></div>
          <div><img src="../assets/supply/s6.png" /></div>
        </div>
      </div>
    </div>

    <div class="su-service">
      <div class="page1200">
        <div class="t1">我们的服务</div>
        <div class="d-flex position-relative">
          <div class="su-s-left">
            <div>
              <div class="l-tit">专业服务</div>
              <div class="l-msg">7*24小时，全天候专业服务</div>
            </div>
            <div>
              <div class="l-tit">全国云仓</div>
              <div class="l-msg">一件代发，实时跟踪，全程无忧</div>
            </div>
            <div style="height:170px">
              <div class="l-tit">专业物流</div>
              <div class="l-msg">京东、顺丰等物流合作，分布在北上广深<br /> 等一二线城市，最快可次日到达</div>
            </div>
            <div>
              <div class="l-tit">售后无忧</div>
              <div class="l-msg">7天无理由退换货</div>
            </div>
          </div>
          <div class="su-s-right">
            <img src="../assets/supply/su-service.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="su-flow">
      <div class="page1200">
        <div class="t1" style="padding: 40px 0px;">对接流程</div>
        <div style="padding-bottom: 80px;">
          <img src="../assets/supply/su-flow.png" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {

}
</script>
<style scoped lang="scss">
.su-bg {
    background: #f6f8fc;
    min-width: 1200px;
}
.banenr {
    height: 640px;
    min-width: 1200px;
    background: url("../assets/supply/banner.png") no-repeat;
    min-width: 1200px;
    background-position: 50% 50%; /*图片上下左右居中*/
    background-size: cover;
}
.su-introduce {
    height: 760px;
    background: url("../assets/supply/bg.png") no-repeat;
    min-width: 1200px;
    background-position: 50% 50%; /*图片上下左右居中*/
    background-size: cover;
    .su-intro-msg {
        .t1 {
            margin: 40px 0px;
            text-align: left;
        }
        margin-bottom: 50px;
    }
    .su-msg {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.65);
        width: 510px;
        line-height: 28px;
        margin-bottom: 80px;
    }
    .item {
        width: 230px;
        height: 220px;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 16px;
        margin-right: 16px;
        padding: 20px;
        transition: all 0.5s;
        &:hover {
            height: 250px;
            transition: all 0.5s;
            background: #0070f3;
            margin-top: -10px;
            > div,
            p {
                color: #d9eafd;
            }
        }
        > div {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            &.item-tit {
                margin-top: 8px;
                margin-bottom: 16px;
            }
        }
        p {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            font-weight: normal;
        }
    }
}
.t1 {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    text-align: center;
}
.t2 {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
}
.t3 {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.85);
}
.su-ys {
    .t1 {
        margin-top: 40px;
    }
    .tit-msg {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 33px;
        margin-bottom: 20px;
        margin-top: 64px;
    }
    .one {
        height: 450px;
        div:first-child {
            margin-right: 15px;
        }
        > div {
            transition: all 0.5s;
        }
        > div:hover {
            margin-top: -8px;
            transition: all 0.5s;
        }
    }
    .two {
        div {
            margin-bottom: 15px;
            margin-right: 15px;
            &:nth-child(3n) {
                margin-right: 0px;
            }
        }
    }
    .three {
        height: 280px;
        div {
            margin-right: 15px;
            transition: all 0.5s;
        }
        div:hover {
            margin-top: -6px;
            transition: all 0.5s;
        }
    }
    .four {
        height: 1035px;
        margin-top: 9px;
        div {
            margin-right: 15px;
            margin-bottom: 45px;
            &:nth-child(2n) {
                margin-right: 0px;
            }
            transition: all 0.5s;
        }
        div:hover {
            margin-top: -9px;
            transition: all 0.5s;
        }
    }
}
.su-service {
    background: #fff;
    .t1 {
        padding: 40px 0px;
    }
    .su-s-left {
        width: 640px;
        margin-bottom: 64px;
        > div {
            height: 154px;
            padding-left: 100px;
            border-radius: 24px;
            transition: all 0.5s;
        }
        > div:hover {
            background-image: linear-gradient(to right, #cee3fd, #fafcff);
            filter: blur(100);
            transition: all 0.5s;
            .l-tit {
                color: #0070f3;
            }
            .l-msg {
                color: #4c9bf7;
            }
        }
        .l-tit {
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 24px;
            font-weight: bold;
            padding-top: 40px;
        }
        .l-msg {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.65);
            padding-bottom: 40px;
        }
    }
    .su-s-right {
        position: absolute;
        z-index: 0;
        left: 500px;
        img {
            padding-top: 105px;
        }
    }
}
</style>
